export default class Workbreak {
    uid: number
    start: number
    stop: number
    startWorkstation: number
    stopWorkstation: number
    employee: number

    constructor(uid: number, start: number, stop: number, startWorkstation: number, stopWorkstation: number, employee: number) {
        this.uid = uid
        this.start = start
        this.stop = stop
        this.startWorkstation = startWorkstation
        this.stopWorkstation = stopWorkstation
        this.employee = employee
    }

    static createBreakFromResponse(res:any) {
        const employee = res.employee?.uid || 0
        return new Workbreak(res.uid, res.start, res.stop, res.startWorkstation, res.stopWorkstation, employee);
    }

    static createWorkbreaksFromResponse(res:any) {
        const workbreaks :any = [];
        const temp = res.data.workbreaks
        temp.forEach((element: any) => {
            const employee = element.employee?.uid || 0
            if (element) workbreaks.push(new Workbreak(element.uid, element.start, element.stop, element.startWorkstation, element.stopWorkstation, employee)
        )})
        return workbreaks;
    }

    getUid() {
        return this.uid;
     }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getStart() {
        return this.start;
     }

     setStart(start:number) {
         this.start = start;
     }  

     getStop() {
        return this.stop;
     }
 
     setStop(stop:number) {
         this.stop = stop;
     }  

     getStartWorkstation() {
        return this.startWorkstation;
     }
     
     setStartWorkstation(startWorkstation:number) {
         this.startWorkstation = startWorkstation;
     }  

     getStopWorkstation() {
        return this.stopWorkstation;
     }
 
     setStopWorkstation(stopWorkstation:number) {
         this.stopWorkstation = stopWorkstation;
     }  

     getTimePaused() {
        let gone = 0
        //for(const key in this.startstops) {
        //this.startstops.forEach((st: any) => {
            if (this.stop) {
                gone += (this.stop - this.start)
            } else {
                gone += ((Date.now() / 1000) - this.start)
            }
        //}
        return gone        
     }
}