import axios from "axios";
import { ObjectStorage, Employee, Presence } from "@/model/index";
import { Api } from "../api";
import AxiosErrorService from "@/service/AxiosErrorService";

const PresenceRepository = {

    presences: new ObjectStorage([]),

    async getPresences(postData:any){
        let result = null
        await axios.post(Api.BACKEND_URL + Api.GET_PRESENCES_ACTION, postData)
        .then((response:any) => {
                console.log('getPresences res', response)
                this.presences = new ObjectStorage(Presence.createPresencesFromResponse(response));
                result = this.presences
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
         });
        return result;
    },

    async getPresence(uid:any){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_PRESENCE_ACTION + uid)
            //console.log('getPresence res', res)
            return res;
        } catch(e){
            console.log('getPresence error:', e);
            return {}
        }
    },
    async getActivePresence(employeeId:any){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_ACTIVE_PRESENCE_ACTION + employeeId)
            //console.log('getActivePresence res', res)
            return res.data.presence;
        } catch(e){
            console.log('getActivePresence error:', e);
            return {}
        }
    },
    async createPresence(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PRESENCE_ACTION, postData)
            //console.log('createPresence res', res)
            return res.data.presence;
        } catch(e){
            console.log('createPresence error:', e);
            return {}
        }
    },
    
    async endPresence(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.END_PRESENCE_ACTION, postData)
            //console.log('updatePresence res', res)
            return res.data.presence;
        } catch(e){
            console.log('updatePresence error:', e);
            return {}
        }
    },

    async updatePresence(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_PRESENCE_ACTION, postData)
            console.log('updatePresence res', res)
            return Presence.createPresenceFromResponse(res.data.presence);
        } catch(e){
            console.log('updatePresence error:', e);
            return {}
        }
    },

}
export default PresenceRepository;