
import { defineComponent } from "vue";
import store from "@/store/index";
import { IconButton } from '@/ui/index';
import { Converter, DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "Table",
    components: { IconButton },
    props:{
        tableData: {
            type: Object,
            default: null
        },
        defaultSortField:{
            type: String,
            default: ""
        },
        src: {
            type: Array as any,
            default: null
        },   
        icon:{
            type: String,
            default: ""
        },
        emptyStateText: {
            type: String,
            default: ""
        },
        lookupTables: {
            type: Object,
            default: null
        }
    },
    data(){
        return{
            store,
            currentSortField: "",
            sortedAsc: true,
            hover: "#",
            sortedList: [] as Array<any>
        }
    },
    mounted() {
        if(this.defaultSortField) {
            this.currentSortField = this.defaultSortField;
        } else {
            this.currentSortField = this.tableData.thFields[0].property;
        }
        this.sortTable(this.currentSortField)
    },
    watch: {
        src() {
            this.sortTable(this.currentSortField)
        }
    },
    computed: {
        emptyState(){
            if(this.src.length == 0 ||  this.src == null){
                return true;
            } else {
                return false;
            }
        }
    },
    methods:{
        handleSortBy(field:any){
            if(field == this.currentSortField){
                this.sortedAsc = !this.sortedAsc
            } else {
                this.currentSortField = field;
            }
            this.sortTable(field)
        },
        sortTable(field:any) {
            var sorted = [...this.src];

            if(field.type == "string" && this.sortedAsc) {
                sorted = sorted.sort(function (a :any, b:any) {
                    return (a[field.property]?a[field.property]:'zz').toLowerCase().localeCompare((b[field.property]?b[field.property]:'zz').toLowerCase());
                });
            } else if(field.type == "string" && !this.sortedAsc) {
                sorted = sorted.sort(function (a :any, b:any) {
                    return (b[field.property] ? b[field.property]:'zz').toLowerCase().localeCompare((a[field.property]?a[field.property]:'zz').toLowerCase());
                });
            }
            this.sortedList = sorted;
        }, 
        startHover(item:any) {
            this.hover = item;
        },
        endHover() {
            this.hover = "";
        },
        isItemSelected(item:any) {
            if (item && typeof item.state != "undefined") {
                return item.state.isSelected;
            } else if (item && typeof item.isSelected != "undefined") {
                return item.isSelected;
            }
        },
        checkTBField(index : any, item : any, field : any){
            let output = null;  
        for(const [key, value] of Object.entries(item)){
            if (field.property == key){
                if (field.type == 'objects') {
                    if (typeof value === 'object') {
                        return Object.keys(value as any).length
                    }
                } else if (field.type == 'lookup') {
                    const table = this.lookupTables[field.lookupTable]
                    if (table) {
                        const dataset = table.find((tableItem: any) => tableItem.id == item[key])
                        if (dataset) {
                            output = dataset[field.lookupField]
                        } else {
                            output = '-'
                        }
                    } else {
                        output = '-'
                    }
                } else {
                    if (field.type == 'number') {
                        if (field.format == 'currency') {
                            output = value ? parseFloat(value as string).toLocaleString('de-DE', { style: 'currency', currency: 'EUR',  minimumFractionDigits: field.digits ? field.digits : 0 }) : '-'
                        } else {
                            output = value ? parseFloat(value as string).toLocaleString('de-DE') : '-'
                        }
                    } else {
                        output = value
                    }
                }
            } else if(field.property.includes(".")) {
                //console.log(field.property, item[field.property.split(".")[0]]);
                if(item[field.property.split(".")[0]]){
                    if (item[field.property.split(".")[0]][field.property.split(".")[1]]) {
                        output = item[field.property.split(".")[0]][field.property.split(".")[1]]
                    } else {
                        if (typeof item[field.property.split(".")[0]] === 'object') {

                            for (const [subproperty, subvalue] of Object.entries(item[field.property.split(".")[0]])) {
                                const obj:any = subvalue
                                if (obj && obj[field.property] && obj[field.property.split(".")[1]]) {
                                    output = (output ? ' ' : '') + obj[field.property.split(".")[1]]
                                }
                            } 
                            //output = 'ich habe ein object'
                        }
                    }
                }
            }           
        } 
        if(field.convert && output) {
            if(field.convert == "convertSecondsToTime") {
                output =  Converter.convertSecondsToTime(output)
            } else if (field.convert == "convertTimeStampToTime") {
                output =  DateTimeUtils.convertTimeStampToTime(output)                   
            } else if (field.convert == "convertTimeStampToDate"){
                output =  Converter.convertTimeStampToDate(output)
            } else if (field.convert == 'convertToDate') {
                output = Converter.convertToDate(output)
            } 
        }

        if (output) {
            return output;
        }

        return "-";

            /*let output = null;  
            for(const [key, value] of Object.entries(item)){
                if (field.property == key){
                    if (field.type == 'objects') {
                        if (typeof value === 'object') {
                            return Object.keys(value as Record<string, unknown>).length
                        }
                    } else {
                        output = value;
                    }
                } else if(field.property.includes(".")) {
                    if(item[field.property.split(".")[0]]){
                        if (item[field.property.split(".")[0]][field.property.split(".")[1]]) {
                            output = item[field.property.split(".")[0]][field.property.split(".")[1]];
                        } else {
                            if (typeof item[field.property.split(".")[0]] === 'object') {
                                for (const subvalue of Object.entries(item[field.property.split(".")[0]])) {
                                    const obj:any = subvalue
                                    if (obj && obj[field.property] && obj[field.property.split(".")[1]]) {
                                        output = (output ? ' ' : '') + obj[field.property.split(".")[1]]
                                    }
                                } 
                                //output = 'ich habe ein object'
                            }
                        }
                    }
                }           
            } 
            if(field.convert && output) {
                if(field.convert == "convertSecondsToTime") {
                    output =  Converter.convertSecondsToTime(output)
                } else if (field.convert == "convertTimeStampToTime") {
                    output =  DateTimeUtils.convertTimeStampToTime(output)                   
                } else if (field.convert == "convertTimeStampToDate"){
                    output =  Converter.convertTimeStampToDate(output)
                } else if (field.convert == 'convertToDate') {
                    output = Converter.convertToDate(output)
                } /* else if (field.convert == 'extractDateOnly') {
                    output = Converter.extractDateOnly(output)
                } */
            /* }

            if (output) {
                return output;
            }

            return "-"; */
        },


        /*checkTBField(index : any, item : any, field : any){
            let output = null;  
            for(const [key, value] of Object.entries(item)){
                if (field.property == key){
                    output =  value;
                } else if(field.property.includes(".")) {
                    if(item[field.property.split(".")[0]]){
                        output =  item[field.property.split(".")[0]][field.property.split(".")[1]];
                    }
                }           
            } 
            if(field.convert) {
                if(field.convert == "convertSecondsToTime"){
                    output =  Converter.convertSecondsToTime(output);
                } else if (field.convert == "convertTimeStampToDate"){
                    output =  Converter.convertTimeStampToDate(output);
                }
            }

            if (output != null) {
                return output;
            }

            return "--";
        }, */
        handleActionClicked(action:string, item:any ) {
            if(action){
                this.$emit("onActionClicked", action, item)
            }
        }
    }
})
