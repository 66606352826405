//import Store from "@/store";
//import { ViewController, StatementController, LinkController, ModelUserController, RatingController } from "@/controller/index.js";
import { NfcChipRepository } from "../repository";

const UpdateController = {
    nfcChipId: '',
    updateInterval: 1500,
    updateTimeout: -1,
    
    updateInProgress: false,
    lastUpdateFetched: 0,
    updateStopped: false,
    route: {} as any,

        //callbacks
    beforeCheckUpdate() { /* set this */ },
    onUpdate(nfcChipId: string) { /* quick and dirty */ console.log('onUpdate', nfcChipId) },
    afterCheckUpdate() { /* for workaround ts compiler */ },

    getUpdateInProgress() {
        return this.updateInProgress;
    },

    setUpdateInProgress(updateInProgress: boolean) {
        this.updateInProgress = updateInProgress;
    },

    getLastUpdateFetched() {
        return this.lastUpdateFetched;
    },

    setLastUpdateFetched(updateFetched: number) {
        this.lastUpdateFetched = updateFetched;
    },

    getRoute() {
        return this.route;
    },

    setRoute(route:any) {
        this.route = route;
        console.log("UpdateController:setRoute:route", this.route);
    },

    getBeforeCheckUpdate() {
        return this.beforeCheckUpdate;
    },

    setBeforeCheckUpdate(beforeCheckUpdate: any) {
        this.beforeCheckUpdate = beforeCheckUpdate;
    },

    getOnUpdate() {
        return this.onUpdate;
    },

    setOnUpdate(onUpdate: any) {
        this.onUpdate = onUpdate;
    },

    getAfterCheckUpdate() {
        return this.afterCheckUpdate;
    },

    setAfterCheckUpdate(afterCheckUpdate: any) {
        this.afterCheckUpdate = afterCheckUpdate;
    },    

    update() {
        window.clearTimeout(UpdateController.updateTimeout);
        UpdateController.updateTimeout = window.setTimeout(() => {
            UpdateController.checkUpdate();
            if (!this.updateStopped) UpdateController.update();
        }, UpdateController.updateInterval);
    },

    startUpdate() {
        this.updateStopped = false;
        this.update();
    },

    stopUpdate() {
        this.updateStopped = true;
        window.clearTimeout(UpdateController.updateTimeout);
    },

    async checkUpdate() {
        //console.log("UpdateController:this.route", this.route);
        if (this.route.fullPath.indexOf('/workspace') != -1) await this.fetchNfcChipUpdate();
        //if (this.route.name === "Project") await this.fetchModelUpdates();
        //if (this.route.name === "Rating" && this.ratingId) await this.fetchRatingUpdates();
        //if (this.route.name === "Preferencing" && this.preferencingId) await this.fetchPreferencingUpdates();
    },

    async fetchNfcChipUpdate() {

        if (UpdateController.getUpdateInProgress()) {
            return;
        }

        if (this.beforeCheckUpdate && typeof(this.beforeCheckUpdate) != 'undefined' && typeof this.beforeCheckUpdate === "function") {
            this.beforeCheckUpdate();
        }

        UpdateController.setUpdateInProgress(true)

        this.nfcChipId = await NfcChipRepository.getUpdate()
        if (this.nfcChipId) this.onUpdate(this.nfcChipId)

        //if (response && response.data.success) {
            //console.log("UpdateController:fetchNfcChipUpdate:response", response);

            //this.checkPreferencingUpdates(response);
           // UpdateController.setLastUpdateFetched(response.data.tstamp);
        //}

        UpdateController.setUpdateInProgress(false);

        if (this.afterCheckUpdate && typeof this.afterCheckUpdate === "function") {
            this.afterCheckUpdate();
        }

    }

}

//UpdateController.startUpdate();

export default UpdateController;
