import { WorkstationRepository } from "../repository";

const WorkstationController = {

    setLocalStorageUuidWorkstation(uuid: string) {
        return localStorage.setItem('TimiTools.UuidWorkstation', uuid)
    },

    getLocalStorageUuidWorkstation() {
        return localStorage.getItem('TimiTools.UuidWorkstation')
    },

    async fetchWorkstations(){
        const res : any = await WorkstationRepository.fetchWorkstations();
        return res;
    },
    async getWorkstationById(uid:any){
        const res : any = await WorkstationRepository.getWorkstationById(uid)
        return res;
    },
    async createWorkstation(formData : any) {
        const postData = `title=${formData.title}&department=${formData.department}&workarea=${formData.workarea}&unit=${formData.machine}&nfcchipReader=${formData.nfcChipReader}`;
        const res : any = await WorkstationRepository.createWorkstation(postData)
        return res;
    },
    async updateWorkstation(formData : any) {
        const postData = `uid=${formData.uid}&title=${formData.title}&department=${formData.department}&workarea=${formData.workarea}&unit=${formData.machine}&nfcChipReader=${formData.nfcChipReader}`;
        const res : any = await WorkstationRepository.updateWorkstation(postData);
        return res;
    },
    async deleteWorkstation(workstationId : number) {
        const postData = `uid=${workstationId}`;
        const res : any = await WorkstationRepository.deleteWorkstation(postData);
        return res;
    }
}
export default WorkstationController;