
import store from '@/store/index';
import { Button, IconButton } from '@/ui/index';
import { defineComponent, onMounted, ref, computed, watch} from 'vue';
import { Unique } from '@/utils';

export default defineComponent({
  name: "SelectSingleSearch",
  components: { Button, IconButton },
  props: {
      item: {
        type: Object,
        default: null
      },
      options: {
        type: Array,
        default: null
      },
      value: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      primary: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ""
      },
      allowCreateNew: {
        type: Boolean,
        default: false
      },
      subheader: {
        type: Array,
        default: null
      },
      labelProperty: {
        type: String,
        default: ""
      },
      emptyStateText: {
        type: String,
        default: ""
      },
      secondary: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      store,
      icons: {
        chevrondown: '<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M0.799988 1.31L8.79999 9.31L16.8 1.31" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
        undo: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><g><path d="M3.1,4.1c1.3-1.3,3-2.1,4.9-2.1,3.9,0,7,3.1,7,7s-.5,3-1.4,4.2c-1.3,1.7-3.3,2.8-5.6,2.8-3.9,0-7-3.1-7-7" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/><line x1="2.1" y1="5" x2="3.1" y2="4.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/><polyline points="2.1 2.5 2.1 5 4.6 5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/></g></svg>'
      }
    }
  },
  setup(props, { emit }) {
    const domCustomSelect = ref<HTMLElement|null>(null);
    const domSelectList = ref<HTMLElement|null>(null);
    const cId = Unique.getUnique()
    const modelValue = ref(0)
    const inputValue = ref("")
    const undoInputText = ref("")
    const undoInputValue = ref(0)
    const filteredOptions = ref([] as Array<any>)
    const listActive = ref(false);
    const enableAddButton = ref(false);
    const selectedValue = ref({
      text: "",
      value: 0
    });

    const bodyClickHandler = ref()

    const selectListStyle = computed(() => {
      let style = "";
      if (domCustomSelect.value) {
        
        if (listActive.value) {
          const br = domCustomSelect?.value?.getBoundingClientRect()
          const selBr = domSelectList?.value?.getBoundingClientRect()
          const wH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
          if ((Number(selBr?.height) + br.top +16) > wH) {
            style = 'bottom: ' + Number(br.height) +  'px; width: ' + (br.width - (props.secondary ? 2 : 0)) + 'px;'
          } else {
            style = 'top: ' + Number(br.height) +  'px; width: ' + (br.width - (props.secondary ? 2 : 0)) + 'px;'
          }
        } else {
          style = ''
        }
      }
      return style;
    })

    const componentClass = computed(() => {
      let cClass = "";
      if (domCustomSelect.value) {
        if (listActive.value) {
          const br = domCustomSelect?.value?.getBoundingClientRect()
          const selBr = domSelectList?.value?.getBoundingClientRect()
          const wH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
          if ((Number(selBr?.height) + br.top +16) >= wH) {
            cClass = 'list-bottom'
          } else {
            cClass = 'list-top'
          }
        } else {
          cClass = ''
        }
      }
      cClass += props.secondary ? ' secondary' : ''
      return cClass
    })    

    const filterOptions = () => {
        let filteredList : Array<any> = props.options;     
        if (inputValue.value) {
          filteredList = filteredList.filter((item:any)=>{
            if((inputValue.value && item[props.labelProperty].toLowerCase().includes(inputValue.value.toLowerCase()) || !inputValue.value)) {
              return item
            } 
          })
        }
        if (inputValue.value != "" && listActive.value == false) {
          handleToggleList();
          if (filteredList.length == 1 && inputValue.value == filteredList[0][props.labelProperty]) {
            handleHideList();
          }
        }
        filteredOptions.value = filteredList
    }

    const resetFilterOptions = () => {
      filteredOptions.value = props.options
    }

    const undoChanges = () => {
      inputValue.value = undoInputText.value
      modelValue.value = undoInputValue.value
      selectedValue.value.value = undoInputValue.value
      selectedValue.value.text = undoInputText.value
      resetFilterOptions()
      emit("onChange", props.item, modelValue.value, inputValue.value)
    }

    const handleInputKeyDown = (ev: KeyboardEvent) => {
      if (ev.key == 'Escape') {
        undoChanges()
        listActive.value = false
      }
    }

    const handleChangeInput = () => {
      filterOptions()

      if (listActive.value && (filteredOptions.value && !filteredOptions.value.some(el => el[props.labelProperty] == inputValue.value))) {
        enableAddButton.value = true;
      } else {
        enableAddButton.value = false;
      }
      emit("onChange", props.item, modelValue.value, inputValue.value);
    }

    const handleUndoChanges = () => {
      undoChanges()
    }

    onMounted(() => {
      filterOptions()
    })

    const handleHideList = () => {
      listActive.value = false;
    }

    const isSelectedItem = (uid:any) => {
      if(uid == selectedValue.value.value) {
        return true;
      } 
      return false;
    }

    bodyClickHandler.value = (ev:Event) => {
      const composed = ev.composedPath();
      if (composed) {
        const close = composed.filter((el: any) => el.classList && el.classList.contains('input-action-bar') && el?.id == 'selectSingleSelectList'+cId);
      
        if (!close.length) {
            //reset input
          undoChanges()
          listActive.value = false;
        }
      }
    }

    const handleToggleList = () => {
      listActive.value = !listActive.value;
      document.body.addEventListener("click", bodyClickHandler.value);          
    }

    const handleSelectListClick = (ev : Event) => {
      const target = ev?.target as HTMLElement;

      if (target.nodeName == "LI") {
        selectedValue.value.value = Number(target.dataset['value'])
        modelValue.value = Number(target.dataset['value']);
        inputValue.value = target.innerHTML.trim();
          //TODO: check usability
        //undoInputText.value = target.innerHTML.trim();
        selectedValue.value.text = target.innerHTML.trim();
        listActive.value = false;
        emit("onChange", props.item, modelValue.value, selectedValue.value.text);
      }
      handleHideList();
    }

    const handleAddItemClicked = (ev : Event) => {
      handleHideList()
      emit("onAddItemClicked", inputValue.value)
      inputValue.value = ""
    }

    watch(() => props.value, (oldValue, newValue) => {
      //console.log('watch props.storedValue, oldValue, newValue', props.value)
      modelValue.value = props.value.value
      selectedValue.value.value = props.value.value
      selectedValue.value.text = props.value.text
      inputValue.value = props.value.text
      undoInputText.value = (!undoInputText.value ? props.value.text : undoInputText.value)
      undoInputValue.value = (!undoInputValue.value ? props.value.value : undoInputValue.value)
      //listActive.value = false;
    })

    watch(() => listActive.value, (newValue, oldValue) => {
      if (!newValue) document.body.removeEventListener('click', bodyClickHandler.value)
    })

    watch(() => props.options, () => {
      filterOptions()
    })

    return {
      domCustomSelect,
      domSelectList,
      cId,
      modelValue,
      inputValue,
      listActive,
      selectedValue,
      selectListStyle,
      undoInputValue,
      filteredOptions,
      enableAddButton,
      componentClass,
      isSelectedItem,
      handleToggleList,
      handleHideList,
      handleSelectListClick,
      handleAddItemClicked,
      handleChangeInput,
      handleInputKeyDown,
      handleUndoChanges
    }
  },
})
