export default class Project {
    uid: number;
    numberExternal:string;
    numberInternal:string;
    numberSerial:string;
    title: string;
    customer: any;
    active: boolean;
    archived: boolean;
    timeBudget: number;
    moneyBudget: number;
    accounted: boolean;
   
    constructor(uid: number, numberExternal:string, numberInternal:string, numberSerial: string, title: string, customer: any, active: boolean, archived: boolean, timeBudget:number, moneyBudget:number, accounted:boolean  ) {
        this.uid = uid;
        this.numberExternal = numberExternal;
        this.numberInternal = numberInternal;
        this.numberSerial = numberSerial;
        this.title = title;
        this.customer = customer;
        this.active = active;
        this.archived = archived;
        this.timeBudget = timeBudget;
        this.moneyBudget = moneyBudget;
        this.accounted = accounted;
    }

    static createProjectsFromResponse(res:any) {
        const projects :any = [];
        res.data.projects.forEach((element: any) => {
            projects.push(new Project (element.uid, element.numberExternal, element.numberInternal, element.numberSerial, element.title, element.customer, element.active, element.archived, element.timeBudget, element.moneyBudget, element.accounted)
        )})
        return projects;
    }

    static createProjectFromResponse(res:any) {
        //console.log('Project[createProjectFromResponse] -> res', res);
        return new Project (res.uid, res.numberExternal, res.numberInternal, res.numberSerial, res.title, res.customer, res.active, res.archived, res.timeBudget, res.moneyBudget, res.accounted);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getNumberExternal() {
        return this.numberExternal;
    }

    setNumberExternal(numberExternal:string) {
        this.numberExternal = numberExternal;
    }

    getNumberInternal() {
        return this.numberInternal;
    }

    setNumberInternal(numberInternal:string) {
        this.numberInternal = numberInternal;
    }

    getNumberSerial() {
        return this.numberSerial;
    }

    setNumberSerial(numberSerial:string) {
        this.numberSerial = numberSerial;
    }

    getTitle() {
        return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }  

    getProjectTitle() {
        let title = this.numberExternal ? this.numberExternal : ''
        title += this.numberInternal ? (title ? ' / ' : '') + this.numberInternal : ''
        title += this.numberSerial ? (title ? ' / ' : '') + this.numberSerial : ''
        title += this.title ? (title ? ' ' : '') + this.title : ''
        return title
    }

    getCustomer() {
        return this.customer;
    }

    setCustomer(customer:any) {
        this.customer = customer;
    }

    getActive() {
        return this.active;
    }

    setActive(active:boolean) {
        this.active = active;
    }  
    getArchived() {
        return this.archived;
    }

    setArchived(archived:boolean) {
        this.archived = archived;
    }  

    getFullTitle() {
        let title = this.numberExternal ? this.numberExternal : ''
        title += this.numberInternal ? (title ? ' / ' : '') + this.numberInternal : ''
        title += this.numberSerial ? (title ? ' / ' : '') + this.numberSerial : ''
        title += this.title ? (title ? ' ' : '') + this.title : ''
        return title
    }
}