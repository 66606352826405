import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Workbreak } from "@/model";
import AxiosErrorService from "@/service/AxiosErrorService";

const WorkbreakRepository = {
    async getWorkbreak(uid:any){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_WORKBREAK_ACTION + uid)
            //console.log('getWorkbreak res', res)
            return res;
        } catch(e){
            console.log('getWorkbreak error:', e);
            return {}
        }
    },
    async getActiveWorkbreak(employeeId:any){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_ACTIVE_WORKBREAK_ACTION + employeeId)
            //console.log('getActiveWorkbreak res', res)
            return res.data.workbreak;
        } catch(e){
            console.log('getActiveWorkbreak error:', e);
            return {}
        }
    },
    async getWorkbreaks(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.GET_WORKBREAKS_ACTION, postData)
            //console.log('getDayWorkbreaks res', res)
            return new ObjectStorage(Workbreak.createWorkbreaksFromResponse(res));
        } catch(e){
            console.log('getDayWorkbreaks error:', e);
            return {}
        }
    },      
    async getDayWorkbreaks(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.GET_DAY_WORKBREAKS_ACTION, postData)
            //console.log('getDayWorkbreaks res', res)
            return new ObjectStorage(Workbreak.createWorkbreaksFromResponse(res));
        } catch(e){
            console.log('getDayWorkbreaks error:', e);
            return {}
        }
    },    
    async createWorkbreak(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CREATE_WORKBREAK_ACTION, postData)
            //console.log('createWorkbreak res', res)
            return res.data.workbreak;
        } catch(e){
            console.log('createWorkbreak error:', e);
            return {}
        }
    },
    
    async stopWorkbreak(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.STOP_WORKBREAK_ACTION, postData)
            //console.log('updateWorkbreak res', res)
            return res.data.workbreak;
        } catch(e){
            console.log('updateWorkbreak error:', e);
            return {}
        }
    },  
    async deleteWorkbreak(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.DELETE_WORKBREAK_ACTION, postData)
            //console.log('updateWorkbreak res', res)
            return Workbreak.createBreakFromResponse(res.data.workbreak);
        } catch(e){
            console.log('updateWorkbreak error:', e);
            return {}
        }
    },        

    async updateWorkbreak(postData:any){
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_WORKBREAK_ACTION, postData)
            console.log('updateWorkbreak res', res)
            return Workbreak.createBreakFromResponse(res.data.workbreak);
        } catch(e){
            console.log('updateWorkbreak error:', e);
            return {}
        }
    },    
}

export default WorkbreakRepository;
