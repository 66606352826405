import { Address, Contact, User, Employeegroup, Presence, TimeEntry, Department, Project, Workbreak } from "./"

export default class Employee {
    uid: number;
    number : string;
    firstName: string;
    lastName: string;
    siNumber: any;
    nfcChipId: string
    image: any
    imageSource:string
    gender: number
    title: string
    dateOfBirth: string
    workplace: number
    address: Address | null
    contact: Contact | null
    feUser: User | null
    activeProjects: Project[]
    group: Employeegroup | null
    departments: Department[]

        //runtime only for reactiveness must be set 
    presence: Presence | null
    timeEntries: any[]
    daySum: number
    workbreaks: Workbreak[]
    workbreakSum: number

    constructor(
        uid : number, number : string, firstName : string, lastName : string, siNumber: any, nfcChipId: string, activeProjects : Array<Project>, image:any, imageSource:string, 
        gender:number, title:string, dateOfBirth:string, workplace:number, address: Address | null, contact: Contact | null, feUser: User | null, 
        employeeGroup: Employeegroup | null, departments: Array<Department>
    ){
        this.uid = uid;
        this.number = number;
        this.firstName = firstName;
        this.lastName = lastName;
        this.siNumber = siNumber
        this.nfcChipId = nfcChipId
        this.activeProjects = activeProjects;
        this.image = image;
        this.imageSource = imageSource
        this.gender = gender
        this.title = title
        this.dateOfBirth = dateOfBirth
        this.workplace = workplace
        this.address = address
        this.contact = contact
        this.feUser = feUser
        this.group = employeeGroup
        this.departments = departments
            //runtime only
        this.presence = null
        this.timeEntries = []
        this.daySum = 0
        this.workbreaks = [],
        this.workbreakSum = 0
    }

    static createEmployeesFromResponse(res:any) {

        const employees :any = [];
        res.data.employees.forEach((element: any) => {
            const address = (element.addresses ? Address.createAddressFromResponse(element.addresses[Object.keys(element.addresses)[0]]) : null)
            const contact = (element.contacts ? Contact.createContactFromResponse(element.contacts[Object.keys(element.contacts)[0]]) : null)
            const feUser = (element.feUser ? User.createUserFromResponse(element.feUser) : null)
            const group = (element.group ? Employeegroup.createEmployeegroupFromResponse(element.group) : null)
            const departments = [] as Array<Department>
            if (element.departments) {
                for(const key in element.departments) {
                    departments.push(Department.createDepartmentFromResponse(element.departments[key]))
                }
            }
            const projects = [] as Array<Project>
            if (element.activeProjects) {
                for(const key in element.activeProjects) {
                    projects.push(Project.createProjectFromResponse(element.activeProjects[key]))
                }
            }
            employees.push(new Employee(   
                element.uid, element.number, element.firstName, element.lastName, element.siNumber, element.nfcChipId, projects, element.image, element.imageSource, 
                element.gender, element.title, element.dob, element.workplace, address, contact, feUser, group, departments
            )
        )})
        return employees;
    }

    static createEmployeeFromResponse(res:any) {
        //console.log('lengths addresses | contacts', res.addresses, (Object.keys(res.contacts) ? 'habe keys' : 'keinen key'))
        const address = (res.addresses ?  Address.createAddressFromResponse(res.addresses[Object.keys(res.addresses)[0]]) : null)
        const contact = (res.contacts ? Contact.createContactFromResponse(res.contacts[Object.keys(res.contacts)[0]]) : null)
        const feUser = (res.feUser ? User.createUserFromResponse(res.feUser) : null)
        const group = (res.group ? Employeegroup.createEmployeegroupFromResponse(res.group) : null)
        const departments = [] as Array<Department>
        if (res.departments) {
            for(const key in res.departments) {
                departments.push(Department.createDepartmentFromResponse(res.departments[key]))
            }
        }
        const projects = [] as Array<Project>
        if (res.activeProjects) {
            for(const key in res.activeProjects) {
                projects.push(Project.createProjectFromResponse(res.activeProjects[key]))
            }
        }        
        return new Employee(
            res.uid, res.number, res.firstName, res.lastName, res.siNumber, res.nfcChipId, projects, res.image, res.imageSource, 
            res.gender, res.title, res.dob, res.workplace, address, contact, feUser, group, departments
        );
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getNumber() {
        return this.number;
    }
 
     setNumber(number : string) {
         this.number = number;
     } 

     getGender() {
        return this.gender;
     }
 
     setGender(gender:number) {
         this.gender = gender;
     }       

     getTitle() {
        return this.title;
     }
 
     setTitle(title:string) {
         this.title = title;
     }       

     getFirstName() {
        return this.firstName;
     }
 
     setFirstName(firstName:string) {
         this.firstName = firstName;
     }  

     getLastName() {
        return this.lastName;
     }
 
     setLstName(lastName:string) {
         this.lastName = lastName;
     }  

     getDateOfBirth() {
        return this.dateOfBirth;
     }
 
     setDateOfBirth(dateOfBirth:string) {
         this.dateOfBirth = dateOfBirth;
     }  

     getSiNumber() {
        return this.siNumber;
     }
 
     setSiNumber(siNumber:string) {
         this.siNumber = siNumber;
     }  

     getNfcChipId() {
        return this.nfcChipId;
     }
 
     setNfcChipId(nfcChipId:string) {
         this.nfcChipId = nfcChipId;
     }  

     getWorkplace() {
        return this.workplace;
     }
 
     setWorkplace(workplace:number) {
         this.workplace = workplace;
     }  

     getActiveProjects() {
        return this.activeProjects;
     }

     setActiveProjects(projects:Array<any>) {
         this.activeProjects = projects;
     }  

     getAddress() {
        return this.address;
     }
 
     setAddress(address: Address | null) {
         this.address = address;
     }  

     getContact() {
        return this.contact;
     }
 
     setContact(contact: Contact | null) {
         this.contact = contact;
     } 

     getFeUser() {
        return this.feUser;
     }
 
     setFeUser(feUser: User | null) {
         this.feUser = feUser;
     }      

     getGroup() {
        return this.group;
     }
 
     setGroup(group: Employeegroup | null) {
         this.group = group;
     }  

     getPresence() {
        return this.presence;
     }
 
     setPresence(presence: Presence | null) {
         this.presence = presence;
     }  

     getTimeEntries() {
        return this.timeEntries;
     }
 
     setTimeEntries(timeEntries: Array<any>) {
         this.timeEntries = timeEntries;
     }       

     getDepartments() {
        return this.departments
     }

     setDepartments(departments:Array<any>) {
         this.departments = departments
     }  

     getWorkbreaks() {
        return this.workbreaks
     }

     setWorkbreaks(workbreaks:Array<any>) {
         this.workbreaks = workbreaks
     }  

     setRuntimeValues() {
        this.daySum = 0
        this.timeEntries.forEach((entry: TimeEntry) => {
            this.daySum += entry.getTimeGone()
        })

        this.workbreakSum = 0
        this.workbreaks.forEach((wBreak: Workbreak) => this.workbreakSum += (wBreak.stop ? (wBreak.stop - wBreak.start) : ((Date.now() / 1000) - wBreak.start)))
        console.log('workbreakSum', this.workbreakSum, this.workbreaks)
    }
}