export const Api = Object.freeze({
    TIMITOOLS_VERSION: "v0.1",
    //BACKEND_URL: "/backend/api/v1",
    BACKEND_URL: process.env.VUE_APP_BACKEND,
    //BACKEND_URL: "https://hiag.timi.tools/backend/api/v1",
    GET_STPID: "/pid",

    LOGIN_ACTION:"/login",
    LOGOUT_ACTION:"/logout",
    FORGOT_PASSWORD: "/user/forgotpassword",
    RESET_PASSWORD: "/user/resetpassword",
    GET_USERS_ACTION: "/users",
    
    GET_SESSION_USER:"/session/user",
    GET_SESSION_COMPANY: "/session/company",

    GET_EMPLOYEES_ACTION:"/employees",
    GET_EMPLOYEE_BY_ID_ACTION: "/employee/",
    GET_EMPLOYEE_BY_CHIP_ACTION: "/employee/",
    CREATE_EMPLOYEE_ACTION: "/employee/create",
    UPDATE_EMPLOYEE_ACTION: "/employee/update",
    DELETE_EMPLOYEE_ACTION: "/employee/delete",
    EMPLOYEE_ADDPROJECT_ACTION: "/employee/addproject",
    EMPLOYEE_REMOVEPROJECT_ACTION: "/employee/removeproject",
    EMPLOYEE_UPDATE_CHIPID_ACTION: "/employee/updatechipid",

    GET_EMPLOYEEGROUPS_ACTION:"/employeegroups",
    GET_EMPLOYEEGROUP_BY_ID_ACTION: "/employeegroup/",
    CREATE_EMPLOYEEGROUP_ACTION: "/employeegroup/create",
    UPDATE_EMPLOYEEGROUP_ACTION: "/employeegroup/update",

    GET_PROJECTS_ACTION: "/projects",
    GET_PROJECT_BY_ID_ACTION: "/project/",
    GET_PROJECT_ACTION_BY_EMPLOYEE: "/projects/employee/",
    GET_PROJECT_BY_CLIENT_ACTION: "/projects/client/",
    GET_PROJECT_ACTION:"/project/",
    CREATE_PROJECT_ACTION: "/project/create",
    UPDATE_PROJECT_ACTION: "/project/update",
    DELETE_PROJECT_ACTION: "/project/delete",
    TOGGLE_PROJECT_ACTION: "/project/toggle",

    GET_WORKS_ACTION:"/works",
    GET_WORK_BY_ID_ACTION:"/work/",
    CREATE_WORK_ACTION: "/work/create",
    UPDATE_WORK_ACTION: "/work/update",
    DELETE_WORK_ACTION: "/work/delete",

    GET_TIMEENTRIES_ACTION: "/timeentries/",
    GET_TIMEENTRIES_DAY_ACTION: "/timeentries/day",
    GET_TIMEENTRIES_PROJECT_ACTION: "/timeentries/project/",
    GET_TIMEENTRIES_EMPLOYEE_ACTION: "/timeentries/employee",
    GET_TIMEENTRIES_RUNNING_ACTION: "/timeentries/running/", //WS -> added
    GET_TIMEENTRIES_CLIENT_ACTION: "/timeentries/customer/",
    GET_TIMEENTRY_ACTION: "/timeentry/",
    CREATE_TIMEENTRY_ACTION: "/timeentry/create",
    TOGGLE_TIMEENTRY_ACTION: "/timeentry/toggle", //WS -> changed from update to toggle
    DELETE_TIMEENTRY_ACTION: "/timeentry/delete", //WS -> added
    UPDATE_TIMEENTRY_ACTION: "/timeentry/update",

    GET_PRESENCES_ACTION: "/presences",
    GET_PRESENCE_ACTION: "/presence/",
    GET_ACTIVE_PRESENCE_ACTION: "/presence/active/",
    CREATE_PRESENCE_ACTION: "/presence/create",
    END_PRESENCE_ACTION: "/presence/end",
    UPDATE_PRESENCE_ACTION: "/presence/update",

    GET_WORKBREAKS_ACTION: "/workbreaks",    
    GET_WORKBREAK_ACTION: "/workbreak/",    
    GET_ACTIVE_WORKBREAK_ACTION: "/workbreak/active/",
    CREATE_WORKBREAK_ACTION: "/workbreak/create",
    STOP_WORKBREAK_ACTION: "/workbreak/stop",
    UPDATE_WORKBREAK_ACTION: "/workbreak/update",
    DELETE_WORKBREAK_ACTION: "/workbreak/delete",
    GET_DAY_WORKBREAKS_ACTION: "/workbreak/day",

    GET_CLIENTS_ACTION: "/customers",
    GET_CLIENT_BY_ID_ACTION: "/customer/",
    CREATE_CLIENT_ACTION: "/customer/create",
    UPDATE_CLIENT_ACTION: "/customer/update",
    DELETE_CLIENT_ACTION: "/customer/delete",
    TOGGLE_CLIENT_ACTION: "/customer/toggle",

    GET_COMPANY_ACTION: "/company/",
    POST_COMPANYSETTINGS_ACTION : "/companysettings/post",
    
    GET_DEPARTMENTS_ACTION: "/departments",
    GET_DEPARTMENT_BY_ID_ACTION: "/department/",
    CREATE_DEPARTMENT_ACTION: "/department/create",
    UPDATE_DEPARTMENT_ACTION: "/department/update",
    DELETE_DEPARTMENT_ACTION: "/department/delete",

    GET_WORKAREAS_ACTION: "/workareas",
    GET_WORKAREA_BY_DEPARTMENT_ACTION: "/workareas/department/",
    GET_WORKAREA_BY_ID_ACTION: "/workarea/",
    CREATE_WORKAREA_ACTION: "/workarea/create",
    UPDATE_WORKAREA_ACTION: "/workarea/update",
    DELETE_WORKAREA_ACTION: "/workarea/delete",

    GET_MACHINES_ACTION: "/units",
    GET_MACHINE_BY_ID_ACTION: "/unit/",
    CREATE_MACHINE_ACTION: "/unit/create",
    UPDATE_MACHINE_ACTION: "/unit/update",
    DELETE_MACHINE_ACTION: "/unit/delete",

    GET_WORKSTATIONS_ACTION: "/workstations",
    GET_WORKSTATION_BY_ID_ACTION: "/workstation/",
    CREATE_WORKSTATION_ACTION: "/workstation/create",
    UPDATE_WORKSTATION_ACTION: "/workstation/update",
    DELETE_WORKSTATION_ACTION: "/workstation/delete",

    GET_RIGHTS_ACTION: "/rights",

})